import { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Spinner } from 'react-bootstrap';

import SupplyCategories from './SupplyCategories';
import ShippingInfo from './ShippingInfo'
import Header from './Header'
import { MissingDataModal, SuccessModal } from './Modals'



import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css'

// const accountGraphql = 'http://localhost:4000/graphql';
const emailGraphql = 'https://send-email-service-bjudfyf5za-uc.a.run.app/graphql';
const accountGraphql = 'https://lab-order-gql-bjudfyf5za-uc.a.run.app/graphql';

const App = () => {
    // const cookie = document.cookie;
    // console.log('cookie: ', cookie);

    const [supplies, setSupplies] = useState([{
        supplyCategoryID: "",
        supplyCategory: "",
        order: "",
        header: "",
        footer: "",
        supplies: [{
            supplyID: "",
            supplyName: "",
            supplyDescription: "",
            emailName: "",
            order: "",
            supplyCategoryID: ""
        }]
    }]);
    const [customerInformation, setCustomerInformation] = useState({
        accountNumber: "",
        fullName: "",
        emailName: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
        labLocation: "",
        supplies: ""
    })
    const [missingData, setMissingData] = useState([])
    const [availableAccounts, setAvailableAccounts] = useState([]);
    const [handleMissingDataModal, setHandleMissingDataModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [sendingOrder, setSendingOrder] = useState(false);
    const [userId, setUserId] = useState("");
    const [midWestPMSFeedBags, setMidWestPMSFeedBags] = useState("")
    const [accountAddress, setAccountAddress] = useState({
        address: "",
        city: "",
        state: "",
        zipCode: ""
    });
    const [userAddress, setUserAddress] = useState({
        address: "",
        city: "",
        state: "",
        zipCode: ""
    });
    const [selectedAddress, setSelectedAddress] = useState("")

    // // function gets a specific cookie
    function getCookie(cName) {
        const name = cName + "=";
        // const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = document.cookie.split('; ');
        let res;
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res
    }

    const fetchData = async () => {
        // // will be used to get userName from cookie
        let userName;
        const neededCookie = await getCookie("username");
        // console.log('neededCookie: ', neededCookie);
        userName = neededCookie;


        const suppliesListQuery = `{customerList}`;
        const suppliesListFetch = await fetch(accountGraphql, { method: 'POST', body: JSON.stringify({ query: suppliesListQuery }), headers: { 'Content-Type': 'application/json' } });
        const suppliesListJSON = await suppliesListFetch.json();
        const suppliesListResponse = JSON.parse(suppliesListJSON.data.customerList);

        const suppliesList = [...suppliesListResponse];
        for (let i = 0; i < suppliesList.length; i++) {
            if (suppliesList[i].supplyCategoryID === 2) {
                const feedSupplies = suppliesList[i].supplies;
                for (let idx = 0; idx < feedSupplies.length; idx++) {
                    if (feedSupplies[idx].supplyID === 231) {
                        setMidWestPMSFeedBags(feedSupplies[idx])
                        feedSupplies.splice(idx, 1)
                        break;
                    }
                }
                break;
            }

        }

        setSupplies(suppliesList)
        // console.log(suppliesListResponse);
        // console.log('userName: ', userName);
        if (userName !== "" && userName !== undefined) {
            const accountsQuery = `{customerInfo(userName:${JSON.stringify(userName)})}`
            const accountFetch = await fetch(accountGraphql, { method: 'POST', body: JSON.stringify({ query: accountsQuery }), headers: { 'Content-Type': 'application/json' } });
            const accountJSON = await accountFetch.json();
            const accountResponse = JSON.parse(accountJSON.data.customerInfo);
            const customerAccounts = [];
            accountResponse[0].accounts.forEach((x) => {
                customerAccounts.push(x)
            })
            // console.log(customerAccounts.length);
            if (customerAccounts.length < 1) {
                setAvailableAccounts([])
            } else {
                setAvailableAccounts(customerAccounts);
            }

            let clientName = "";
            let clientEmail = "";
            let clientStreet = "";
            let clientCity = "";
            let clientState = "";
            let clientZipCode = "";
            let clientPhoneNumber = "";
            if (accountResponse[0].info.length !== 0) {
                if (accountResponse[0].info[0].fullName !== "" && accountResponse[0].info[0].fullName !== "&lt;Not Specified&gt;") {
                    clientName = accountResponse[0].info[0].fullName;
                }
                if (accountResponse[0].info[0].email !== "" && accountResponse[0].info[0].email !== "&lt;Not Specified&gt;") {
                    clientEmail = accountResponse[0].info[0].email;
                }
                if (accountResponse[0].info[0].street !== "" && accountResponse[0].info[0].street !== "&lt;Not Specified&gt;") {
                    clientStreet = accountResponse[0].info[0].street;
                }
                if (accountResponse[0].info[0].city !== "" && accountResponse[0].info[0].city !== "&lt;Not Specified&gt;") {
                    clientCity = accountResponse[0].info[0].city;
                }
                if (accountResponse[0].info[0].state !== "" && accountResponse[0].info[0].state !== "&lt;Not Specified&gt;") {
                    clientState = accountResponse[0].info[0].state;
                }
                if (accountResponse[0].info[0].postalCode !== "" && accountResponse[0].info[0].postalCode !== "&lt;Not Specified&gt;") {
                    clientZipCode = accountResponse[0].info[0].postalCode;
                }
                if (accountResponse[0].info[0].phoneNumber !== "" && accountResponse[0].info[0].phoneNumber !== "&lt;Not Specified&gt;") {
                    clientPhoneNumber = accountResponse[0].info[0].phoneNumber;
                }
                if (accountResponse[0].info[0].userid !== "" && accountResponse[0].info[0].userid !== "&lt;Not Specified&gt;") {
                    setUserId(accountResponse[0].info[0].userid);
                }
            }

            setCustomerInformation(prevState => {
                return {
                    ...prevState,
                    fullName: clientName,
                    emailName: clientEmail,
                    phoneNumber: clientPhoneNumber
                }
            });
            setUserAddress({
                address: clientStreet,
                city: clientCity,
                state: clientState,
                zipCode: clientZipCode,
            })
        }
    }

    const handleUpdateAccountNumber = async (e) => {
        e.preventDefault();
        const value = e.target.value;
        setCustomerInformation(prevState => {
            return { ...prevState, accountNumber: value }
        });

        const accountAddressQuery = `
            {
                accountAddressInfo(accountId:${JSON.stringify(value)}){
                    accountId
                    name
                    street
                    street2
                    city
                    state
                    zipCode
                }
            }
        `;
        const accountAddressFetch = await fetch(accountGraphql, { method: 'POST', body: JSON.stringify({ query: accountAddressQuery }), headers: { 'Content-Type': 'application/json' } });
        const accountAddressJson = await accountAddressFetch.json();
        const accountAddressResp = accountAddressJson.data.accountAddressInfo;
        if (accountAddressResp.street !== '') {
            setAccountAddress({
                address: accountAddressResp.street,
                city: accountAddressResp.city,
                state: accountAddressResp.state,
                zipCode: accountAddressResp.zipCode
            })
        } else {
            setAccountAddress({
                address: accountAddressResp.street2,
                city: accountAddressResp.city,
                state: accountAddressResp.state,
                zipCode: accountAddressResp.zipCode
            })
        }
    }
    const handleUpdateContactName = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setCustomerInformation(prevState => {
            return { ...prevState, fullName: value }
        });
    }
    const handleUpdateEmailName = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setCustomerInformation(prevState => {
            return { ...prevState, emailName: value }
        });
    }
    const handleUpdateAddress = (e) => {
        e.preventDefault();
        const value = e.target.value;
        let addressToUse = value;
        if (value.indexOf('\'') >= 0) {
            addressToUse = value.replace('\'', " ")
        }
        if (value.indexOf('"') >= 0) {
            addressToUse = value.replace('"', " ")
        }
        setUserAddress(prevState => {
            return { ...prevState, address: addressToUse }
        });
        if (selectedAddress === 'userAd') {
            setCustomerInformation(prevState => {
                return { ...prevState, address: addressToUse }
            });
        }
    }
    const handleUpdateCity = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setUserAddress(prevState => {
            return { ...prevState, city: value }
        });
        if (selectedAddress === 'userAd') {
            setCustomerInformation(prevState => {
                return { ...prevState, city: value }
            });
        }
    }
    const handleUpdateState = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setUserAddress(prevState => {
            return { ...prevState, state: value }
        });
        if (selectedAddress === 'userAd') {
            setCustomerInformation(prevState => {
                return { ...prevState, state: value }
            });
        }
    }
    const handleUpdateZipCode = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setUserAddress(prevState => {
            return { ...prevState, zipCode: value }
        });
        if (selectedAddress === 'userAd') {
            setCustomerInformation(prevState => {
                return { ...prevState, zipCode: value }
            });
        }
    }
    const handleUpdatePhoneNumber = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setCustomerInformation(prevState => {
            return { ...prevState, phoneNumber: value }
        });
    }
    const handleUpdateLabLocation = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setCustomerInformation(prevState => {
            return { ...prevState, labLocation: value }
        });
    }

    const handleRadioAddress = (e) => {
        // console.log(e.target.id);
        const accountType = e.target.id;
        setSelectedAddress(accountType);
        if (accountType === 'userAd') {
            let addressToUse = userAddress.address;
            if (userAddress.address.indexOf('\'') >= 0) {
                addressToUse = userAddress.address.replace('\'', " ")
            }
            if (userAddress.address.indexOf('"') >= 0) {
                addressToUse = userAddress.address.replace('"', " ")
            }
            setCustomerInformation(prevState => {
                return { ...prevState, address: addressToUse }
            });
            setCustomerInformation(prevState => {
                return { ...prevState, city: userAddress.city }
            });
            setCustomerInformation(prevState => {
                return { ...prevState, state: userAddress.state }
            });
            setCustomerInformation(prevState => {
                return { ...prevState, zipCode: userAddress.zipCode }
            });
        } else {
            setCustomerInformation(prevState => {
                return { ...prevState, address: accountAddress.address }
            });
            setCustomerInformation(prevState => {
                return { ...prevState, city: accountAddress.city }
            });
            setCustomerInformation(prevState => {
                return { ...prevState, state: accountAddress.state }
            });
            setCustomerInformation(prevState => {
                return { ...prevState, zipCode: accountAddress.zipCode }
            });
        }
    }

    const handleAddSuppliesForOrder = async (e, x, supplyCategory) => {
        let quantity = 0;
        if (e && e.target) {
            quantity = e.target.value;
        }
        const supplyID = x.supplyID;
        const emailName = x.emailName;
        if (customerInformation.supplies === "" && quantity >= 0) {
            const thingOrdered = [{ quantity, supplyID, supplyCategory, emailName }]
            if (quantity > 0) {
                setCustomerInformation(prevState => {
                    return { ...prevState, supplies: thingOrdered }
                });
            }
        } else if (quantity >= 0) {
            const orderAddition = { quantity, supplyID, supplyCategory, emailName };
            const newSupplies = [];
            customerInformation.supplies.forEach((x, index) => {
                if (x.supplyID !== supplyID) {
                    newSupplies.push(x)
                }
            })
            if (quantity > 0) {
                newSupplies.push(orderAddition)
            }
            setCustomerInformation(prevState => {
                return { ...prevState, supplies: newSupplies }
            });
        }
    }
    const handleReset = () => {
        document.getElementById("order-form").reset();
        setSupplies([{
            supplyCategoryID: "",
            supplyCategory: "",
            order: "",
            header: "",
            footer: "",
            supplies: [{
                supplyID: "",
                supplyName: "",
                supplyDescription: "",
                emailName: "",
                order: "",
                supplyCategoryID: ""
            }]
        }])
        setCustomerInformation({
            accountNumber: "",
            fullName: "",
            emailName: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            phoneNumber: "",
            labLocation: "",
            supplies: ""
        })
        setAccountAddress({
            address: "",
            city: "",
            state: "",
            zipCode: ""
        })
        setUserAddress({
            address: "",
            city: "",
            state: "",
            zipCode: ""
        })
        setSelectedAddress("")
        setMissingData([])
        fetchData()
    }

    const handleSubmit = async () => {
        let emailToAddress;
        const customerMailTo = customerInformation.emailName;
        if (customerInformation.labLocation === "Dodge City") {
            emailToAddress = 'dodgecitysupplies@servi-techinc.com';
            // emailToAddress = 'valeria.lara@servitech.com';
            // emailToAddress = 'dan.morgan@servitech.com';
        } else if (customerInformation.labLocation === "Hastings") {
            emailToAddress = 'hastingssupplies@servi-techinc.com';
            // emailToAddress = 'valeria.lara@servitech.com';
            // emailToAddress = 'dan.morgan@servitech.com';
        } else {
            emailToAddress = 'amarillosupplies@servi-techinc.com';
            // emailToAddress = 'dan.morgan@servitech.com';
            // emailToAddress = 'valeria.lara@servitech.com';
        }
        const missingInfoList = [];
        if (customerInformation.accountNumber === "") {
            missingInfoList.push("Account Option Not Selected")
        }
        if (customerInformation.fullName === "") {
            missingInfoList.push("Contact Name Missing")
        }
        if (customerInformation.emailName === "") {
            missingInfoList.push("Email Address Missing")
        }
        if (customerInformation.address === "") {
            missingInfoList.push("Address Missing")
        }
        if (customerInformation.city === "") {
            missingInfoList.push("City Missing")
        }
        if (customerInformation.state === "") {
            missingInfoList.push("State Missing")
        }
        if (customerInformation.zipCode === "") {
            missingInfoList.push("Zip Code Missing")
        }
        if (customerInformation.phoneNumber === "") {
            missingInfoList.push("Phone Number Missing")
        }
        if (customerInformation.labLocation !== 'Dodge City' && customerInformation.labLocation !== 'Amarillo' && customerInformation.labLocation !== 'Hastings') {
            missingInfoList.push("Lab Location Missing")
        }
        if (customerInformation.supplies === "") {
            missingInfoList.push("No supplies was selected")
        }
        if (selectedAddress === "") {
            missingInfoList.push("Please select a \"Ship To\" option")
        }
        setMissingData(missingInfoList)
        if (missingInfoList.length >= 1) {
            setHandleMissingDataModal(true);
        } else {
            // console.log('sending order');
            setSendingOrder(true);
            const emailQuery = `{sendSupplyOrderEmailFromWebsite(emailTo:${JSON.stringify(emailToAddress)},orderJSON:${JSON.stringify(JSON.stringify(customerInformation))})}`;
            const emailFetch = await fetch(emailGraphql, { method: 'POST', body: JSON.stringify({ query: emailQuery }), headers: { 'Content-Type': 'application/json' } });
            const emailJSON = await emailFetch.json();
            const emailResponse = emailJSON.data.sendSupplyOrderEmailFromWebsite
            const emailConfirmationQuery = `{sendOrderConfirmationEmail(customerEmail:${JSON.stringify(customerMailTo)},orderJSON:${JSON.stringify(JSON.stringify(customerInformation))})}`;
            const emailConfirmationFetch = await fetch(emailGraphql, { method: 'POST', body: JSON.stringify({ query: emailConfirmationQuery }), headers: { 'Content-Type': 'application/json' } });
            const emailConfirmationJSON = await emailConfirmationFetch.json();
            const emailConfirmationResponse = emailConfirmationJSON.data.sendOrderConfirmationEmail
            const limsOrderQuery = `{sendOrder(orderJSON:${JSON.stringify(JSON.stringify(customerInformation))})}`
            const limsOrderFetch = await fetch(accountGraphql, { method: 'POST', body: JSON.stringify({ query: limsOrderQuery }), headers: { 'Content-Type': 'application/json' } })
            const limsOrderJSON = await limsOrderFetch.json();
            const limsOrderResponse = limsOrderJSON.data.sendOrder;
            if (emailResponse === 'email sent' && emailConfirmationResponse === 'email sent') {
                setShowSuccessModal(true)
                setSendingOrder(false)
                setTimeout(() => {
                    handleReset()
                    setShowSuccessModal(false)
                }, 4000)
            }
            // console.log(limsOrderResponse);
            if (limsOrderResponse === 'Order Added to LIMS') {
                const limsEmailQuery = `{sendLIMSOrderConfirmationEmail(emailTo:${JSON.stringify(emailToAddress)},orderJSON:${JSON.stringify(JSON.stringify(customerInformation))})}`
                await fetch(emailGraphql, { method: 'POST', body: JSON.stringify({ query: limsEmailQuery }), headers: { 'Content-Type': 'application/json' } });
            }
        }

    }

    const getCompanySpecificLabels = async () => {
        const customerGroupsForWebUserQuery = `{customerGroupsForWebUser(accountId:${userId})}`
        const customerGroupsForWebUserFetch = await fetch(accountGraphql, { method: 'POST', body: JSON.stringify({ query: customerGroupsForWebUserQuery }), headers: { 'Content-Type': 'application/json' } });
        const customerGroupsForWebUserJSON = await customerGroupsForWebUserFetch.json();
        const parsedCustomerGroupsForWebUser = JSON.parse(customerGroupsForWebUserJSON.data.customerGroupsForWebUser);

        if (parsedCustomerGroupsForWebUser.length && parsedCustomerGroupsForWebUser.includes(1223)) {
            setSupplies(prevData =>
                // map each supply category in prevData (current state for supplies)
                prevData.map(category =>
                    // check if the current category supply is Feed Sampling Supplies  
                    category.supplyCategory === "Feed Sampling Supplies" ?
                        {
                            //if it is, keep the category and add the supplies object for midWestPMSFeedBags
                            ...category,
                            supplies: [...category.supplies, midWestPMSFeedBags]
                        } : category // if it does not match return the original category without changes
                ))
        }
    }

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", fetchData())
        // window.addEventListener("DOMContentLoaded", console.log("done"))
        // eslint-disable-next-line 
    }, [])

    useEffect(() => {
        if (userId !== "" && userId !== undefined && midWestPMSFeedBags !== "" && midWestPMSFeedBags !== undefined) {
            getCompanySpecificLabels()
        }
    }, [midWestPMSFeedBags, userId])

    return (
        <>
            <Header />
            <Form id="order-form" className="order-form">
                <Col className='form-area'>
                    <Row className='form-column-row'>
                        <Row className='form-supplies-row'>
                            <SupplyCategories
                                supplies={supplies}
                                handleAddSuppliesForOrder={handleAddSuppliesForOrder}
                            />
                        </Row>
                        <Row className="sup-mssg-row">
                            <span className='wtr-sup-mssg' >
                                <b>Customer will be billed for supplies shipping costs</b>
                            </span>
                            <span className='wtr-sup-mssg' >
                                <b>Charges will be billed back to the customer when prepaid labels are used to ship samples to the labs</b>
                            </span>
                        </Row>
                        <Row className='form-shipping-row'>
                            <ShippingInfo
                                customerInformation={customerInformation}
                                handleUpdateAccountNumber={handleUpdateAccountNumber}
                                handleUpdateContactName={handleUpdateContactName}
                                handleUpdateEmailName={handleUpdateEmailName}
                                handleUpdateAddress={handleUpdateAddress}
                                handleUpdateCity={handleUpdateCity}
                                handleUpdateState={handleUpdateState}
                                handleUpdateZipCode={handleUpdateZipCode}
                                handleUpdatePhoneNumber={handleUpdatePhoneNumber}
                                handleUpdateLabLocation={handleUpdateLabLocation}
                                availableAccounts={availableAccounts}
                                accountAddress={accountAddress}
                                userAddress={userAddress}
                                handleRadioAddress={handleRadioAddress}
                            />
                        </Row>
                    </Row>
                    <Row className='buttons-row'>
                        <Col xs={12} s={12} md={12} lg={6} xl={6}>
                            {sendingOrder &&
                                <Button className='submit-btn'>
                                    <span style={{ fontSize: "18px", color: "white" }}>Sending &nbsp;</span>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ height: "20px", width: "20px" }}
                                    />
                                </Button>
                            }
                            {!sendingOrder &&
                                <Button className='submit-btn' onClick={handleSubmit}>
                                    <span style={{ fontSize: "18px", color: "white" }}><b>Submit Order</b></span>
                                </Button>
                            }
                            <Button variant="outline-dark" className='cancel-btn' onClick={handleReset}>
                                <span style={{ fontSize: "18px" }}>Cancel Order</span>
                            </Button>
                        </Col>
                    </Row>
                </Col >
                <MissingDataModal
                    isOpen={handleMissingDataModal}
                    setModalOpen={setHandleMissingDataModal}
                    missingData={missingData}
                />
                <SuccessModal
                    isOpen={showSuccessModal}
                    setModalOpen={setShowSuccessModal}
                />
            </Form>
        </>
    )
}
export default App;